import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { HeadingTertiary } from '../../../components/atoms/Heading/HeadingTertiary'
import { useLoadLocalScript } from '../../../components/hooks/useLoadLocalScript'
import { pdfBtn01 } from '../../../components/styles/pdfBtn01'
import { styleIRNews } from '../../../components/styles/styleIRNews'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  // ローカルのscriptタグを埋めたい場合
  useLoadLocalScript('/common/js/ir/XjStorageLoaderStock02.js')

  return (
    <LayoutIR
      heading="定款・株式取扱規程"
      headingEnglish={ENGLISH_TITLE.ArticlesOfIncorporationAndRegulations}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '株式・株主情報', url: '/ir/stock' },
        { title: '定款・株式取扱規程', url: '/' },
      ]}
    >
      <div>
        <div className="py-12 pc:px-8 bg-white">
          <HeadingTertiary label="定款" />
          <div css={pdfBtn01} className="mt-6">
            <div css={styleIRNews} id="xj-mainlist-teikan"></div>
          </div>
        </div>
        <div className="pc:py-12 pc:px-8 mt-14 bg-white">
          <HeadingTertiary label="株式取扱規程" />
          <div css={pdfBtn01} className="mt-6">
            <div css={styleIRNews} id="xj-mainlist-other"></div>
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="定款・株式取扱規程" url="/ir/stock/regulation" />
)
